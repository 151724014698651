import React from 'react'
// import { StaticImage } from 'gatsby-plugin-image'
// import PropTypes from 'prop-types'
// import { Link } from 'gatsby'

export const HomePageTextRectangle = ({ className, children, image, numbering, title, text }) => (
  <div className={`home-page-text-rectangle${className ? ' ' + className : ''}`}>
    {/*<div>*/}
      {/*{*/}
      {/*  !!image*/}
      {/*    ? <StaticImage*/}
      {/*      className='image'*/}
      {/*      src={image.link}*/}
      {/*      alt={`${image.name}`}*/}
      {/*    />*/}
      {/*    : null*/}
      {/*}*/}
    {/*</div>*/}
    {/*<div>*/}
    {children}
    {/*</div>*/}
    <h2 className='numbering'>{numbering}</h2>
    <h2 className='title'>{title}</h2>
    <div className='text'>{text}</div>
  </div>
)

