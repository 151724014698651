import NoteSvg from '../svg/note.svg'
import FolderSvg from '../svg/folder.svg'
import BookSvg from '../svg/book.svg'

export const sectionOne = {
  title: 'Krašto pažinimo ir tyrimų centras',
  paragraph: 'Krašto tyrimai, moksleivių edukacija, vaikų vasaros stovyklos'
}

export const sectionTwo = [
  {
    Svg: NoteSvg,
    title: 'Paslaugos',
    paragraph: 'Centras teikia paslaugas, susijusias su  krašto tyrimais ir taikomąja veikla, vaikų ir jaunimo užimtumu, švietimu.'
  },
  {
    Svg: FolderSvg,
    title: 'Projektai',
    paragraph: 'Krašto pažinimo ir tyrimų centras įgyvendina projektus, skirtus pilietiniam ugdymui, istorinės atminties įprasminimui, vaikų ir jaunimo socializacijai.'
  },
  {
    Svg: BookSvg,
    title: 'Edukacija',
    paragraph: 'Vaikų ir jaunimo ugdymas apima tris veiklos kryptis:  kraštotyrinę, edukacinę ir turistinę - tai yra visų jų simbiozė  turinti ribotus mokslinės krypties elementus.'
  },
]

export const sectionThree = [
  {
    title: 'Krašto pažinimas',
    paragraph: 'Kraštotyra – tai savo krašto pažinimas. Vienas žymiausių Lietuvos filosofų A. Maceina pabrėžė, kad „kraštotyra yra pirmoji ir svarbiausia priemonė pažinti tautinei dabarčiai“.'
  },
  {
    title: 'Edukacija',
    paragraph: 'Mūsų veiklos yra įvairialypės, visapusiškai ugdančios. Pagrindinis edukacinių veiklų tikslas - ugdyti iniciatyvią, aktyvią, atsakingą asmenybę.'
  }
]

export const sectionFour = {
  title: 'Krašto pažinimas',
  paragraph: 'Mollit cornhole roof party, bicycle rights pickled quis man bun flexitarian mixtape aliquip keffiyeh dolore glossier lyft. Tbh shoreditch occaecat authentic seitan scenester, pork belly incididunt lumbersexual letterpress austin.'
}

export const sectionFive = {
  title: 'Galerija',
  paragraph1: 'Cloud bread tilde distillery shaman taxidermy marfa migas slow-carb af pop-up, health goth fixie retro laboris.',
  paragraph2: 'Brooklyn shoreditch before they sold out, synth succulents austin health goth esse listicle enamel pin ut in.'
}

export const sectionSix = {
  title: 'Savanorystė',
  paragraph: 'Norintys įgyti darbo patirties, pagilinti socialinius įgūdžius, realizuoti save, suteikti savo gyvenimui daugiau prasmės - susisiek su mumis.'
}

export const sectionSeven = [
  {
    numbering: '01',
    title: 'Paslaugos',
    paragraph: 'Art party raw denim XOXO meggings locavore farm-to-table. Portland squid shoreditch lo-fi irure chartreuse paleo in.'
  },
  {
    numbering: '02',
    title: 'Projektai',
    paragraph: 'Id elit distillery activated charcoal unicorn, ethical YOLO aesthetic blog try-hard. Quinoa polaroid non mustache.'
  }
]
