import React from 'react'
// import { Link } from 'gatsby'
// import { graphql, Link, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { HomePageSection, HomePageTextRectangle } from '../components'
import {
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
  sectionFive,
  sectionSix,
  sectionSeven,
} from '../assets/texts/home-page'
// import Banner from '../components/Banner'

const HomeIndex = () => {
  // const data = useStaticQuery(graphql`
  //   query HomeIndexQuery {
  //     allFile {
  //       edges {
  //         node {
  //           id
  //           size
  //           name
  //         }
  //       }
  //     }
  //   }
  // `)
  // console.log({ data });
  return (
    <Layout>
      {/*  todo: read about helmet */}
      <Helmet
        title="Krasto Pažinimas"
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      >
      </Helmet>
      
      {/*<Banner />*/}
      
      <div id="main">
        <HomePageSection>
          {/* todo: play with static image size props */}
          <StaticImage
            className="main-image"
            src="../assets/images/main_image.jpg"
            alt='krasto pazinimas'
            width={800}
          />
          
          <HomePageTextRectangle
            title={sectionOne.title}
            text={sectionOne.paragraph}
          />
        </HomePageSection>
        
        <HomePageSection>
          {sectionTwo.map(({ Svg, title, paragraph }) => (
            <HomePageTextRectangle
              key={title}
              className='section-2'
              title={title}
              text={paragraph}
            >
              <Svg />
            </HomePageTextRectangle>
          ))}
        </HomePageSection>
  
        <HomePageSection>
          <div className="home-page-mosaic">
            <StaticImage
              className="main-image"
              src="../assets/images/krasto_pazinimas.jpg"
              alt='baznicia'
            />
  
            {sectionThree.map(({ title, paragraph }) => (
              <HomePageTextRectangle
                key={title}
                className='section-3'
                title={title}
                text={paragraph}
              />
            ))}
  
            <StaticImage
              className="main-image"
              src="../assets/images/edukacija.jpg"
              alt='kardaj'
            />
          </div>
        </HomePageSection>
        
        <HomePageSection className="section-4">
          <HomePageTextRectangle
            className=' white-background'
            title={sectionFour.title}
            text={sectionFour.paragraph}
          />
        </HomePageSection>
        
        <HomePageSection className="section-5">
          <h2 className='title'>{sectionFive.title}</h2>
          
          <div className="content">
            <StaticImage
              src="../assets/images/edukacine_programa.jpg"
              alt='whatever'
            />
  
            <div className="home-page-mosaic">
              <HomePageTextRectangle
                text={sectionFive.paragraph1}
              />
  
              <StaticImage
                className="main-image"
                src="../assets/images/galerija_3.jpg"
                alt='nothing'
              />
              
              
              <StaticImage
                src="../assets/images/galerija_4.jpg"
                alt='go-away'
              />
              
              <HomePageTextRectangle
                text={sectionFive.paragraph2}
              />
            </div>
          </div>
        </HomePageSection>
  
        <HomePageSection className='section-6'>
          <HomePageTextRectangle
            className="white-background"
            title={sectionSix.title}
            text={sectionSix.paragraph}
          />
          
          <div>
            <StaticImage
              src="../assets/images/Krasto_pazinimo_centras8.jpg"
              alt='just-no'
            />
            
            <StaticImage
              src="../assets/images/Krasto_pazinimo_centras7.jpg"
              alt='saywhatagain'
            />
          </div>
        </HomePageSection>
        
        <HomePageSection className='section-7'>
          {sectionSeven.map(({ numbering, title, paragraph}) => (
            <HomePageTextRectangle
              key={title}
              // className='section-3'
              numbering={numbering}
              title={title}
              text={paragraph}
            />
          ))}
        </HomePageSection>
  
        {/*<StaticImage src="../assets/images/Krasto_pazinimo_centras2.jpg" alt='krasto pazinimas' />*/}
        
      {/*  <section id="one" className="tiles">*/}
      {/*    <article style={{ backgroundImage: `url(${pic01})` }}>*/}
      {/*        <header className="major">*/}
      {/*            <h3>Aliquam</h3>*/}
      {/*            <p>Ipsum dolor sit amet</p>*/}
      {/*        </header>*/}
      {/*        <Link to="/landing" className="link primary"></Link>*/}
      {/*    </article>*/}
      {/*    <article style={{ backgroundImage: `url(${pic02})` }}>*/}
      {/*        <header className="major">*/}
      {/*            <h3>Tempus</h3>*/}
      {/*            <p>feugiat amet tempus</p>*/}
      {/*        </header>*/}
      {/*        <Link to="/landing" className="link primary"></Link>*/}
      {/*    </article>*/}
      {/*    <article style={{ backgroundImage: `url(${pic03})` }}>*/}
      {/*        <header className="major">*/}
      {/*            <h3>Magna</h3>*/}
      {/*            <p>Lorem etiam nullam</p>*/}
      {/*        </header>*/}
      {/*        <Link to="/landing" className="link primary"></Link>*/}
      {/*    </article>*/}
      {/*    <article style={{ backgroundImage: `url(${pic04})` }}>*/}
      {/*        <header className="major">*/}
      {/*            <h3>Ipsum</h3>*/}
      {/*            <p>Nisl sed aliquam</p>*/}
      {/*        </header>*/}
      {/*        <Link to="/landing" className="link primary"></Link>*/}
      {/*    </article>*/}
      {/*    <article style={{ backgroundImage: `url(${pic05})` }}>*/}
      {/*        <header className="major">*/}
      {/*            <h3>Consequat</h3>*/}
      {/*            <p>Ipsum dolor sit amet</p>*/}
      {/*        </header>*/}
      {/*        <Link to="/landing" className="link primary"></Link>*/}
      {/*    </article>*/}
      {/*    <article style={{ backgroundImage: `url(${pic06})` }}>*/}
      {/*        <header className="major">*/}
      {/*            <h3>Etiam</h3>*/}
      {/*            <p>Feugiat amet tempus</p>*/}
      {/*        </header>*/}
      {/*        <Link to="/landing" className="link primary"></Link>*/}
      {/*    </article>*/}
      {/*</section>*/}
      {/*  <section id="two">*/}
      {/*    <div className="inner">*/}
      {/*        <header className="major">*/}
      {/*            <h2>Massa libero</h2>*/}
      {/*        </header>*/}
      {/*        <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris aliquet magna*/}
      {/*            magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In efficitur ligula tate urna.*/}
      {/*            Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem*/}
      {/*            consequat tincidunt. Vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus*/}
      {/*            amet pharetra et feugiat tempus.</p>*/}
      {/*        <ul className="actions">*/}
      {/*            <li><Link to="/landing" className="button next">Get Started</Link></li>*/}
      {/*        </ul>*/}
      {/*    </div>*/}
      {/*</section>*/}
      </div>
  
  </Layout>
  )
}
export default HomeIndex
