import React from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'gatsby'

export const HomePageSection = ({ children, className }) => (
  <div className={`home-page-section${className ? ' ' + className : ''}`}>
    {children}
  </div>
)

// export default HomePageSection
